import { DataUsers } from 'containers/Administration/Users/List/model';
import AppAPIDownloadInstance from 'utils/requestDownloadFile';
import AppAPIInstance from 'utils/requestVinova';
import moment from 'moment';
import { API } from '../constans';

export interface IPayload {
  page: number;
  limit: number;
  search?: string;
  role_id?: string;
  role?: string;
  region?: [];
  area?: [];
  territory?: [];
  branch_code?: any[];
  portfolios?: any[];
  manager_type?: string;
  current_manager?: number[];
}

// interface IPayload {
//   page: number;
//   limit: number;
//   search?: string;
//   role_id?: string;
//   region?: [];
//   area?: [];
//   territory?: [];
// }

export const getUsers = async (): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.USER.CREATE_USER);
};

export const getListUsers = async (payload?: IPayload): Promise<any> => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.LIST, payload);
};

export const getUserRoles = async (): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.USER.ROLE);
};

export const getUsersExport = async (payload?: IPayload): Promise<any> => {
  return AppAPIDownloadInstance.post(API.ADMINISTRATION.USER.EXPORT, payload, {
    responseType: 'text',
  }).then(async (response) => {
    const base64String = response.data;

    const binaryString = window.atob(base64String);

    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${'Users'}_${payload.role === 'F' ? 'Mobile' : 'Web'}_${moment(new Date()).format(
      'DD/MM/YYYY',
    )}.xlsx`;
    link.click();
  });
};

export const getUsersDownload = async (): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.USER.DOWNLOAD);
};

export const usersBulkUpload = async (payload: any): Promise<any> => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.UPLOAD, { file_uploaded_url: payload });
};

export const usersCohBulkUpload = async (payload: any): Promise<any> => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.UPLOADCOH, { file_uploaded_url: payload });
};

export const usersCapBulkUpload = async (payload: any): Promise<any> => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.UPLOADCAP, { file_uploaded_url: payload });
};

export const getUsersBulkUploadList = async (payload: any): Promise<any> => {
  return AppAPIInstance.get(API.ADMINISTRATION.USER.UPLOAD, { params: payload });
};

export const getUsersBulkUploadDetail = async (id: any): Promise<any> => {
  return AppAPIInstance.get(`${API.ADMINISTRATION.USER.UPLOAD}/${id}`);
};

export const createUser = async ({ params }: { params: any }) => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.CREATE_USER, params);
};

export const updateUser = async ({ params, id }: { params: DataUsers; id: any }) => {
  return AppAPIInstance.put(API.ADMINISTRATION.USER.UPDATE(id), params);
};

export const updateUserInfo = async ({ payload }: { payload: any }) => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.UPDATE_INFO, payload);
};

export const getDetailUser = async ({ id }: any) => {
  return AppAPIInstance.get(API.ADMINISTRATION.USER.GET_DETAIL(id));
};

export const sendMappingHeaders = async (id: number, payload: any) => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.UPDATE_MAPPING(id), payload);
};
export const sendCohMappingHeaders = async (id: number, payload: any) => {
  return AppAPIInstance.post(API.ADMINISTRATION.USER.UPDATE_COH_MAPPING(id), payload);
};

export const getLocations = async (): Promise<any> => {
  return AppAPIInstance.get(`${API.ADMINISTRATION.USER.LOCATIONS}`);
};

export const getCOHandPendingPaymentCancellation = async (id: any) => {
  return AppAPIInstance.get(API.ADMINISTRATION.PAYMENT.GET_COH_AND_PENDING_PAYMENT_CANCELLATION(id));
};
